export default ({ userLoggedIn }) => {
  const captcha = [
    {
      id: 'captcha',
      tag: 'Recaptcha',
      model: 'captcha',
      rules: ['required']
    }
  ]

  return [
    {
      id: 'document',
      tag: 'InputGroup',
      model: 'document',
      rules: ['required', 'cpfCnpj'],
      props: {
        mask: 'cpfCnpj',
        placeholder: 'CPF ou CNPJ',
        type: 'text',
        invalidLabel: 'Documento inválido',
        theme: 'dark'
      }
    },
    ...(!userLoggedIn ? captcha : []),
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Buscar',
        theme: 'simple',
        color: 'primary-base',
        type: 'submit',
        filled: true,
        textColor: 'white'
      }
    }
  ]
}
