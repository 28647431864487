<template>
  <main class="Tracking">
    <section v-if="!showOrders" class="Tracking__login">
      <div class="Tracking__header">
        <BaseLogo variation="olistPax" class="Tracking__logo" color="white" />
      </div>

      <div class="Tracking__box">
        <span class="Tracking__title">rastreamento do seu pedido</span>
        <DynamicForm
          class="Tracking__form"
          :model="form"
          :blueprint="blueprint({userLoggedIn})"
          :endpoint-errors="endpointErrors"
          @submit="onSubmit"
        />
      </div>
    </section>

    <section v-if="showOrders" class="Tracking__orders">
      <div class="Tracking__header">
        <BaseLogo variation="olistPax" class="Tracking__logo" color="white" />
      </div>

      <div class="Tracking__table">
        <div class="Tracking__tableHeader">
          <BaseButton class="Tracking__iconHeader" icon="chevron-left-alt" filled color="primary-base" text-color="white" @click="cleanOrders" />
    
          <span class="Tracking__order-title">Meus pedidos</span>
        </div>
        
        <div class="Tracking__infos">
          <DataTable
            :header="ordersByDocument.data.headers"
            :table="ordersByDocument.data.content"
            :max-size="false"
            @see="goToOrder"
          />
        </div>
      </div>
    </section>

    <Dialog
      v-if="dialogShow"
      warning="Não existem pedidos para esse número de CPF ou CNPJ"
      :label-cancel="false"
      label-confirm="Ok"
      @confirm="dialogShow = false"
    />
  </main>
</template>

<script>
import { DynamicForm, DataTable, Dialog } from '@/components/molecules'
import { BaseLogo, BaseButton } from '@/components/atoms'
import { mapActions, mapState } from 'vuex'
import blueprint from '@/views/tracking/Form/TrackingForm.blueprint'

export default {
  name: 'Tracking',
  components:{
    BaseLogo,
    BaseButton,
    DynamicForm,
    DataTable,
    Dialog
  },
  data(){
    return {
      dialogShow: false,
      orders: [],
      form: {
        document: '',
        captcha: ''
      },
      endpointErrors: {},
    }
  },
  
  computed:{
    ...mapState({
      ordersByDocument: state => state.tracking.ordersByDocument,
    }),

    showOrders() {
      return this.ordersByDocument.data.content?.length > 0
    },

    userLoggedIn() {
      return !!(JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).name)
    }
  },

  watch:{
    $route (to, from){
      if (from.query.document && to.name === 'tracking' && to.query.document === undefined) {
        window.location.reload()
      }
      if (to.name === 'tracking' && to.query.document) {
        this.form.document = this.$route.query.document
        this.onSubmit()
      }
    }
  }, 

  mounted(){
    if (this.verifyIfUrlHasDocument()) {
      this.form.document = this.$route.query.document
      this.onSubmit()
    }
  },

  methods:{
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchOrdersByDocument: 'tracking/fetchOrdersByDocument',
    }),
    async onSubmit() {
      this.setLoading(true)
      await this.fetchOrdersByDocument({
        document: this.form.document,
      }).then(() => {
        this.verifyIfUserHasOrders()
        this.setLoading(false)
      })
    },
    async cleanOrders() {
      await this.fetchOrdersByDocument({
        params: {},
      })
      this.$router.push('/tracking')
    },    
    goToOrder(data){
      let routeData = this.$router.resolve(
        {
          path: `/tracking/${data.tracking_code}`, 
          query: {'document': this.form.document}
        }
      )
      window.open(routeData.href, '_blank')
    },
    
    verifyIfUrlHasDocument() {

      return this.$route.query.document
    },
    verifyIfUserHasOrders() {
      if (this.ordersByDocument.data.content.length === 0) {
        this.dialogShow = true
      } else {
        this.dialogShow = false
        history.pushState('', '', `?document=${this.form.document}`)
      }
    }
  }
}
</script>

<style lang="scss">
.Tracking {
  background-image: url('../../../assets/images/tracking/bg_login.png');
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .Tracking__login {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 40px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 2rem 0;
    }
  }

  .Tracking__header {
    margin: 0;
    @media screen and (max-width: 768px) {
      margin: 0 0 0 0;
    }
  }

  .Tracking__logo {
    width: 100%;
    text-align: center;
  }

  .Tracking__logo img {
    width: 234px;
  }

  .Tracking__box {
    background-color: white;
    padding: 25px 29px;
    width: 100%;
    border-radius: 17px;
    margin-top: 4rem;

    @media screen and (min-width: 768px) {
      width: 500px ;
    }
  }

  .Tracking__title {
    font-size: $font-size-2xlarge;
    font-weight: $font-weight-bold;
    color: $color-neutral-stronger;
    margin-bottom: 15px;
    display: block;
  }

  .BaseButton {
    height: 53px;
  }

  .InputGroup__input {
    border-bottom: 2px solid #00000029;
  }

  .Recaptcha {
    display: flex;
    justify-content: center;
  }

  .Tracking__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: inherit;
    grid-column-gap: 1.25rem;
    grid-row-gap: 2rem;
    grid-template-areas:  "document"
                          "captcha"
                          "confirm";

    @media screen and (max-width: 570px) {
      width: 100%;
      display: flex;
      flex-direction: column;;
    }
  }

  .Tracking__orders {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 40px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;

    @media screen and (max-width: 768px) {
			flex-direction: column;
			padding: 2rem 0;
		}
  }

  .Tracking__table {
    grid-area: table;
    width: 100%;
    margin-top: 4rem;

    @media screen and (min-width: 768px) {
      width: 1030px ;
    }
  }

  .Tracking__tableHeader {
    background-color: $color-neutral-softest;
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-top: 29px;
    padding-bottom: 10px;
    border-radius: 17px 17px 0 0;
  }

  .Tracking__iconHeader {
    width: 37px;
    height: 37px;
    margin-right: 15px;
  }

  .Tracking__iconHeader img {
    margin-left: 0;
  }

  .Tracking__order-title {
    font-size: $font-size-2xlarge;
    font-weight: $font-weight-bold;
    color: $color-neutral-stronger;
    text-transform:lowercase
  }

  .Tracking__infos {
    background-color: $color-neutral-softest;
    padding: 10px 20px 10px;
    border-radius: 0 0 17px 17px;
    min-height: 240px;
  }

  .DataTable {
    text-transform: lowercase;
  }

  .InputGroup--invalid {
    margin-bottom: 5px;
  }
}
</style>